import './carousel.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { LocalizedLink } from '../localized-link'
import { Button2 } from '../button2'

import { useLangContext } from '../../utils/lang'
import { Container } from '../container'
import { FoodCard } from './food-card'
import styles from './food-carousel.module.css'

const FoodCardListItem = ({ text, title }) => (
  <li>
    <span className={styles.el}>{text}</span>
    <span className={styles.title}>{title}</span>
  </li>
)

const FoodCardList = ({ children }) => (
  <ul className={styles.foodCardList}>{children}</ul>
)

const FoodCarousel = () => {
  const { t } = useTranslation()
  const currentUrl = window.location.href
  const slidesData = currentUrl.includes('novemenu')
    ? [
        {
          img: '/images/novinki-8.jpg',
          title: t('general.foodNovinky.card5'),
          label: t('general.food3.labels.lunch'),
          param1: '253',
          param2: '17g',
          param3: '19g',
          param4: '16g',
        },
        //  {
        //   img: '/images/novinki-6.jpg',
        //   title: t('general.foodNovinky.card6'),
        //   label: t('general.food3.labels.dinner'),
        //   param1: '281',
        //   param2: '27g',
        //   param3: '10g',
        //   param4: '14g',
        // },
        {
          img: '/images/novinki-7.jpg',
          title: t('general.foodNovinky.card7'),
          label: t('general.food3.labels.dinner'),
          param1: '401',
          param2: '47g',
          param3: '27g',
          param4: '10g',
        },
        {
          img: '/images/novinki-5.jpg',
          title: t('general.foodNovinky.card8'),
          label: t('general.food3.labels.lunch'),
          param1: ' 299',
          param2: '8g',
          param3: '23g',
          param4: '18g',
        },
      ]
    : [
        {
          img: '/images/novinki-8.jpg',
          title: t('general.foodNovinky.card5'),
          label: t('general.food3.labels.lunch'),
          param1: '253',
          param2: '17g',
          param3: '19g',
          param4: '16g',
        },
        {
          img: '/images/novinki-6.jpg',
          title: t('general.foodNovinky.card6'),
          label: t('general.food3.labels.dinner'),
          param1: '281',
          param2: '27g',
          param3: '10g',
          param4: '14g',
        },
        {
          img: '/images/novinki-7.jpg',
          title: t('general.foodNovinky.card7'),
          label: t('general.food3.labels.dinner'),
          param1: '401',
          param2: '47g',
          param3: '27g',
          param4: '10g',
        },
        {
          img: '/images/novinki-5.jpg',
          title: t('general.foodNovinky.card8'),
          label: t('general.food3.labels.lunch'),
          param1: ' 299',
          param2: '8g',
          param3: '23g',
          param4: '18g',
        },
      ]

  const slides = slidesData.map(item => (
    <div className={styles.carouselItem} key={item.title}>
      <FoodCard label={item.label} img={item.img} title={item.title}>
        <FoodCardList>
          <FoodCardListItem
            text={item.param1}
            title={t('general.food2.cardInfoLabel1')}
          />
          <FoodCardListItem
            text={item.param2}
            title={t('general.food2.cardInfoLabel2')}
          />
          <FoodCardListItem
            text={item.param3}
            title={t('general.food2.cardInfoLabel3')}
          />
          <FoodCardListItem
            text={item.param4}
            title={t('general.food2.cardInfoLabel4')}
          />
        </FoodCardList>
      </FoodCard>
    </div>
  ))

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    // appendDots: dots => {
    //   return (
    //     <div>
    //       <ul>
    //         {dots.map((item, index) => {
    //           return <li key={index}>this is {item.props.children}</li>
    //         })}
    //       </ul>
    //     </div>
    //   )
    // },
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 4,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          // dots: true,
        },
      },
    ],
  }
  return (
    slidesData !== null &&
    slidesData.length > 0 && (
      <div>
        <Slider {...settings}>{slides}</Slider>
      </div>
    )
  )
}

export const FoodCarouselNovinky = ({ id = 'food' }) => {
  const { t } = useTranslation()
  const { lang } = useLangContext()
  const currentUrl = window.location.href

  return (
    <>
      <section id={id} className={styles.foodCarouselSection}>
        <Container isWide={true}>
          <h3 className="fancyUnderlineText sectionTitleNew text-center">
            <Trans i18nKey="general.foodNovinky.title">
              <span>Novinky</span> měsíce
            </Trans>
          </h3>
          <FoodCarousel />
          {!currentUrl.includes('novemenu') && (
            <div className="text-center" style={{ marginTop: '40px' }}>
              <Button2
                color="outline"
                size="lg"
                className={styles.outsideButton}
              >
                <LocalizedLink
                  to="/new-menu.pdf"
                  target="_blank"
                  style={{
                    padding: '14px 20px',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  {t('general.food.CTA')}
                </LocalizedLink>
              </Button2>
            </div>
          )}
        </Container>
      </section>
    </>
  )
}
